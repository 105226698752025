<template>
  <div>
    <EditTable class="book_list" primary-key="id" :items="books" :fields="fields" :sortFields="sortFields"
      :busy="loading" outlined striped hover selectable sort-icon-left
      @addNew="$router.push({name: 'bke-book-new'})"
      @refresh="getBooks"
      @deleteSelected="OnDeleteSelected"
      @uploadCsv="OnUploadCsv"
    >
      <template v-slot:title>
        <h5>Books List</h5>
      </template>
      <template v-slot:cell(isbn)="cell">
        <router-link :to="{name:'bke-book-view', params:{book_id: cell.item.id}}">{{ cell.item.isbn_13 }}</router-link><br />
        <router-link :to="{name:'bke-book-view', params:{book_id: cell.item.id}}">{{ cell.item.isbn_10 }}</router-link>
      </template>
      <template v-slot:cell(author)="cell">
        {{ cell.value.join(', ') }}
      </template>
      <template v-slot:cell(publish)="cell">
        {{ cell.item.publisher.join(', ') }}<br />
        <YearIcon /> {{ cell.item.published_year }}
      </template>
      <template v-slot:cell(coverimage)="cell">
        <div class="cover row">
          <div class="img_div pr-2" v-for="(v, i) in cell.value.slice(0, maxCover2Show)" :key="i">
            <b-img :src="v" />
          </div>
        </div>
      </template>
    </EditTable>
  </div>
</template>

<script>
import YearIcon from 'mdi-vue/CalendarText';
import { mapState } from 'vuex';
import EditTable from '@/components/ui/EditTable.vue';

export default {
  name: 'BackendListBook',
  components: { YearIcon, EditTable },

  data() {
    return {
      loading: false,
      maxCover2Show: 2,
      fields: [
        { key: 'isbn' },
        { key: 'title', sortable: true },
        { key: 'author', sortable: true },
        { key: 'publish', label: 'Publisher' },
        { key: 'coverimage', label: 'Cover' },
      ],
      sortFields: [
        { key: 'isbn_13', label: 'ISBN 13' },
        { key: 'isbn_10', label: 'ISBN 10' },
        { key: 'publisher', label: 'Publisher' },
        { key: 'published_year', label: 'Pub. Year' },
      ],
    };
  },

  computed: {
    ...mapState('book', {
      books: state => state.books,
    }),
  },

  methods: {
    getBooks() {
      this.loading = true;
      this.$store.dispatch('book/getAllBooks').catch(() => {}).finally(() => {
        this.loading = false;
      });
    },
    OnDeleteSelected(items) {
      this.loading = true;
      const bookIds = items.map(x => x.id);
      this.$store.dispatch('book/deleteBooks', bookIds).catch(() => {
        this.$toast.error('Failed to delete selected items. Try again later.');
      }).finally(() => {
        this.loading = false;
      });
    },
    OnUploadCsv(items) {
      this.$toast.info(`${items.length} item(s) will be imported`);
      // Todo: Add logic for importing CSV entries into the table.
    },
  },

  created() {
    if (!this.books || (this.books.length === 0)) this.getBooks();
  },

};
</script>

<style lang="stylus">

.book_list
  .cover
    min-width 6rem
    .img_div
      position relative
      width 3rem
      img
        width 100%

</style>
